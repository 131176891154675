import axios from "axios";
import { SERVER_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit"

// export const forgotPassword = async ({email}) => {
    
// 	return axios.post(`${SERVER_URL}/account/resetpasswordrequest`,{ email})
// };


export const forgotPassword = createAsyncThunk(
    "users/forgotPassword",
    async ({  email}, thunkAPI) => {
      try {
        const response = await axios.post(`${SERVER_URL}/account/resetpasswordrequest`,{ email})
      
       const {data}=response;
       if (response.status === 200) {           
            return data
          } else {
            return thunkAPI.rejectWithValue(data)
          }
      } catch (e) {
       
        return thunkAPI.rejectWithValue(e.response.data)
      }
    }
  )
import { createSlice } from "@reduxjs/toolkit";
import {setModal} from '../actions/utils/setModal';


export const modalSlice = createSlice({
  name: "modal",
  initialState: {
   open:false,
   active:'',
   isClosable:true,
   modalProps:{}   
  },
  reducers: {
    closeModal: (state) => {
      state.open = false;
      state.active = null;
      state.isClosable = true;
      state.modalProps = {};
            return state;
    },   
  },
  
  extraReducers: {
    [setModal.fulfilled]: (state, { payload }) => {
    
          state.open = true;
          state.active = payload.modal;
          state.isClosable = payload.isClosable;
          state.modalProps = payload.modalProps;
         return state
        },
    
    }
});
export const { closeModal } = modalSlice.actions;
export const modalSelector = state => state.modal
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../../utils/constants';

export const searchTemplate = createAsyncThunk("autotrack/searchTemplate", async ({ val }, thunkAPI) => {
    try {
        const url = `${SERVER_URL}/dashboard/campaign/searchtemplates?searchParam=${val}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem("token"),
            },
        };
        const response = await axios.get(url, config);
        const { data } = response;
        if (response.status === 200) {
            return data.result;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});


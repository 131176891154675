import { createSlice } from '@reduxjs/toolkit';
import { signupUser } from '../actions/auth/register_user';

import { getRole, login } from '../actions/auth/login';
import { forgotPassword } from '../actions/auth/forgot-password';
import { updateProfile } from '../actions/auth/update-profile';
import { cloneDeep } from 'lodash';
export const userSlice = createSlice({
	name: 'user',
	initialState: {
		username: '',
		email: '',
		role: 'lead',
		user: null,
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: '',
		registrationData: null,
		reloadCheck: false
	},
	reducers: {
		setReloadCheck: (state, { payload }) => {
			state.reloadCheck = payload;
		},
		setRegistrationData: (state, action) => {
			state.registrationData = action.payload;
		},
		updateRegistrationData: (state, action) => {
			state.registrationData = {
				...state.registrationData,
				...action.payload
			};
		},
		setUser: (state, { payload }) => {
			state.username = payload.user.fullName;
			state.email = payload.user.email;
			state.role = getRole(payload?.user?.userroleid);
			state.user = { ...payload.user, role: getRole(payload?.user?.userroleid) };
			state.isFetching = false;
			state.isSuccess = true;
			localStorage.setItem('userid', payload.user.userid);
			localStorage.setItem('userroleid', payload.user.userroleid);
			localStorage.setItem(
				'user',
				JSON.stringify({ ...payload.user, role: getRole(payload?.user?.userroleid) })
			);
			return state;
		},
		clearState: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isFetching = false;
			state.registrationData = null;
			return state;
		},
		logoutUser: (state) => {
			state.username = '';
			state.email = '';
			state.role = '';
			state.user = null;
			state.isFetching = false;
			state.isSuccess = false;
			state.isError = false;
			state.errorMessage = '';
			setTimeout(() => window.location.reload(), 300);
			return state;
		},
		socialLogin: (state, { payload }) => {
			state.username = payload.user.fullName;
			state.fullName = payload.user.fullName;
			state.email = payload.user.email;
			state.role = payload.user.role;
			state.user = payload.user;
			return state;
		}
	},

	extraReducers: {
		[signupUser.fulfilled]: (state, { payload }) => {
			state.email = payload.email;
			state.username = payload.fullName;
			state.isError = false;
			state.errorMessage = '';
		},
		[signupUser.pending]: (state) => {
			state.isFetching = true;
		},
		[signupUser.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		[login.fulfilled]: (state, { payload }) => {
			state.user = payload;
			state.email = payload.email;
			state.role = payload.role;
			state.isFetching = false;
			state.isSuccess = true;
			return state;
		},
		[login.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		[login.pending]: (state) => {
			state.isFetching = true;
		},
		[updateProfile.fulfilled]: (state, { payload }) => {
			state.user = payload;
			state.email = payload.email;
			state.role = payload.role;
			state.isFetching = false;
			state.isSuccess = true;
			return state;
		},
		[updateProfile.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		[updateProfile.pending]: (state) => {
			state.isFetching = true;
		},
		[forgotPassword.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isSuccess = true;
			return state;
		},
		[forgotPassword.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		[forgotPassword.pending]: (state) => {
			state.isFetching = true;
		}
	}
});
export const {
	clearState,
	logoutUser,
	socialLogin,
	setRegistrationData,
	setUser,
	updateRegistrationData,
	setReloadCheck
} = userSlice.actions;
export const userSelector = (state) => state.user;
export const userRoleSelector = (state) => state?.user?.role ?? 'lead';
export const userIdSelector = (state) => state?.user?.user?.userid;

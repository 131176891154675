/*
 * SPDX-FileCopyrightText: 2021 Zextras <https://www.zextras.com>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */
import './splash.css';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/Icon/logo192.png';
import fullName from '../../assets/svg/logoFullName.svg';
import { styled, Box, Stack } from '@mui/material';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 8,
	borderRadius: 5,
	// [`&.${linearProgressClasses.colorPrimary}`]: {
	// 	backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
	// },
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: '#2F80E3'
	}
}));

const Loader = () => {
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress >= 80) {
					return 85;
				}

				return oldProgress + 20;
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);
	return (
		// <div className="splash">
		// 	<img src={logo} alt="logo" />
		// 	{/* <div className="loader">
		// 		<div className="bar"></div>
		// 	</div> */}
		// 	<img src={fullName} alt="logo" />
		// </div>
		<Box
			sx={{
				background: '#efefef',
				width: '100vw',
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Stack gap={4}>
				<img src={fullName} alt="logo"  />
				{/* <img src={logo} alt="logo" /> */}
				<BorderLinearProgress
					variant="determinate"
					value={progress}
					disableShrink
					color="primary"
				/>
			</Stack>
		</Box>
	);
};

export default Loader;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';


export const updateLabel = createAsyncThunk('leads/updateLeadLabel', async ({  colourHex,groupId,labelId,labelName }, thunkAPI) => {
	try {
		const url = `${SERVER_URL}/dashboard/labels`;
		const config = {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		};
		const response = await axios.put(url, {  colourHex,groupId,labelId,labelName },config);

		const { data } = response;
		

		if (response.status === 200) {
			return data.result;
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e) {
		
		return thunkAPI.rejectWithValue(e.response.data);
	}
});
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';
import { hostingurl } from '../../../utils/helper-functions';

export const addAgent = createAsyncThunk(
	'agent/addAgent',
	async ({ firstName, lastName, email, mobile, companyName, userLevel = 'Agent' }, thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/agents/invite`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const payload = {
				firstName,
				lastName,
				email,
				mobile,
				companyName,
				userLevel,
                hostingurl
			};
			const response = await axios.post(url, payload, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

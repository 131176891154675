import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../../utils/constants';

export const autotrackStatus = createAsyncThunk(
	'autotrackSlice/autotrackStatus',
	async (body, thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/campaign/autotrackstatus`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const response = await axios.put(url, body, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

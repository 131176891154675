import { createSlice } from '@reduxjs/toolkit';
import { getFavProperites } from '../actions/properties/get-favourite-properties';

export const helperSlice = createSlice({
	name: 'helperSlice',
	initialState: {
		favourites: [],
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: ''
	},
	reducers: {
		resetFav: (state) => {
			state.favourites = [];
			return state;
		}
	},

	extraReducers: {
		[getFavProperites.fulfilled]: (state, { payload }) => {		
			state.isFetching = false;
			state.isSuccess = true;
			state.favourites = payload.property_favorites;
		},
		[getFavProperites.pending]: (state) => {
			state.isFetching = true;
		},
		[getFavProperites.rejected]: (state, { payload }) => {		
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		}
	}
});
export const { resetFav } = helperSlice.actions;
export const favSelector = (state) => state.helpers.favourites;

import { createSlice } from '@reduxjs/toolkit';
import { concat, map, cloneDeep, filter } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { deleteCalEvent } from '../actions/calendar/delete-cal-event';
import { getAppointments } from '../actions/calendar/get-user-appointments';
import { getReminders } from '../actions/calendar/get-user-reminders';

export const calendarSlice = createSlice({
	name: 'calendar',
	initialState: {
		reminders: [],
		appointments: [],
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: '',
		remindersAddCheck: false,
		reload: true
	},
	reducers: {
		setRelaodEvents: (state, { payload }) => {
			state.reload = payload;
		},
		setRemindersAddCheck: (state, { payload }) => {
			state.remindersAddCheck = payload;
		}
	},

	extraReducers: {
		[getReminders.pending]: (state) => {
			state.isFetching = true;
		},
		[getReminders.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[getReminders.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.reminders =
				payload.map((event) => {
					const date = moment(event.date).format('YYYY-MM-DD');
					const time = event.time;
					let newDate = new Date(`${date} ${time}`);
					return {
						type: 'reminder',
						org: event,
						eventColor: '#008000',
						id: event.id,
						title: event.name,
						description: event?.detail ?? 'No Description Provided',
						users: map(event?.attendees, (att) => ({ ...att, username: att?.email })),
						location: event?.location ?? 'No Location Provided',
						start: new Date(moment(newDate).format('YYYY-MM-DD HH:mm')),
						end: new Date(moment(newDate).format('YYYY-MM-DD HH:mm')),
						date: date,
						time: time
					};
				}) ?? [];

			return state;
		},
		[getAppointments.pending]: (state) => {
			state.isFetching = true;
		},
		[getAppointments.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[getAppointments.fulfilled]: (state, { payload }) => {
			console.log('mnop:', { payload });
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.appointments =
				payload?.map((event) => {
					return {
						type: 'appt',
						org: event,
						id: event.id,
						eventColor: event?.eventColor ?? '#825DDE',
						description: event?.description ?? 'No Description Provided',
						title: event.summary,
						link: event?.hangoutLink ?? event.htmlLink,
						location: event?.location ?? 'No Location Provided',
						users: map(event?.attendees, (att) => ({ ...att, username: att?.email })),
						formatedStart: moment(
							moment.tz(event.start.dateTime, event.start.timeZone).format('YYYY-MM-DD HH:mm')
						).format('YYYY-MM-DD HH:mm'),
						formatedEnd: moment(
							moment.tz(event.end.dateTime, event.end.timeZone).format('YYYY-MM-DD HH:mm')
						).format('YYYY-MM-DD HH:mm'),
						start: new Date(
							moment.tz(event.start.dateTime, event.start.timeZone).format('YYYY-MM-DD HH:mm')
						),
						end: new Date(
							moment.tz(event.end.dateTime, event.end.timeZone).format('YYYY-MM-DD HH:mm')
						)
					};
				}) ?? [];

			return state;
		},
		[deleteCalEvent.fulfilled]: (state, { payload, meta }) => {
			console.log('mnop:', { payload: cloneDeep(payload), meta });
			state.isFetching = false;
			if (meta?.arg?.type === 'appt') {
				const newAppointments = filter(
					state.appointments,
					(rem) => rem?.org?.id !== meta?.arg?.eventId
				);
				state.appointments = newAppointments;
			} else {
				const newReminders = filter(state.reminders, (rem) => rem?.org?.id !== meta?.arg?.eventId);
				state.reminders = newReminders;
			}
			return state;
		}
	}
});
export const { setRelaodEvents, setRemindersAddCheck } = calendarSlice.actions;
export const reminderSliceSelector = (state) => state.calendar;
export const remindersSelector = (state) => state.calendar.reminders;
export const appointmentSelector = (state) => state.calendar.appointments;
export const eventReloadSelector = (state) => state.calendar.reload;
export const allEventsSelector = (state) =>
	concat(state.calendar.reminders, state.calendar.appointments);

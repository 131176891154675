// import { SERVER_URL } from "../../../utils/constants";
import { useMemo } from 'react';
import { SERVER_URL } from '../utils/constants';

export function customEmailUrl({
	pgNo = 1,
	limit = 25,
	activePage,
	activeSearchKeyword,
	pageToken,
	firstAttempt,
	previousPage,
	initialCommunication
}) {
	console.log('adfsce', {
		pageToken,
		activeSearchKeyword,
		activePage,
		firstAttempt,
		previousPage,
		initialCommunication
	});
	if (initialCommunication) {
		return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}`;
	}
	if (previousPage === activePage && pgNo > 1) {
		if (activeSearchKeyword !== '') {
			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=from:${activeSearchKeyword}`;
		} else {
			// N?A unread / personal
			if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=is:${activePage}`;
			} else if (
				activePage === 'CATEGORY_FORUMS' ||
				activePage === 'CATEGORY_UPDATES' ||
				activePage === 'CATEGORY_PERSONAL' ||
				activePage === 'CATEGORY_PROMOTIONS' ||
				activePage === 'CATEGORY_SOCIAL' ||
				activePage === 'STARRED'
			) {
				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=category:${activePage
					.split('_')[1]
					.replaceAll('.', '')}`;
			} else {
				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`;
			}
		}
	} else {
		if (activeSearchKeyword !== '') {
			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activeSearchKeyword}`;
		} else if (activePage !== '') {
			if (pgNo > 1) {
				if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=is:${activePage}`;
				} else if (
					activePage === 'CATEGORY_FORUMS' ||
					activePage === 'CATEGORY_UPDATES' ||
					activePage === 'CATEGORY_PERSONAL' ||
					activePage === 'CATEGORY_PROMOTIONS' ||
					activePage === 'CATEGORY_SOCIAL' ||
					activePage === 'STARRED'
				) {
					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=category:${activePage
						.split('_')[1]
						.replaceAll('.', '')}`;
				} else {
					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`;
				}
			} else {
				// return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`
				if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=is:${activePage}`;
				} else if (
					activePage === 'CATEGORY_FORUMS' ||
					activePage === 'CATEGORY_UPDATES' ||
					activePage === 'CATEGORY_PERSONAL' ||
					activePage === 'CATEGORY_PROMOTIONS' ||
					activePage === 'CATEGORY_SOCIAL' ||
					activePage === 'STARRED'
				) {
					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=category:${activePage
						.split('_')[1]
						.replaceAll('.', '')}`;
				} else {
					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`;
				}
			}
		}
	}
}

// export const customEmailUrl = (pgNo = 1, limit = 25, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication) => {
// 	// eslint-disable-next-line react-hooks/rules-of-hooks
// 	// return useMemo(() => getEmailUrl({ pgNo, limit, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication }), [activePage, activeSearchKeyword, firstAttempt, initialCommunication, limit, pageToken, pgNo, previousPage])
// 	return getEmailUrl({ pgNo, limit, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication })
// }

// import { SERVER_URL } from "../../../utils/constants";
// import { useMemo } from 'react';
// import { SERVER_URL } from '../utils/constants';

// export function customEmailUrl({
// 	pgNo = 1,
// 	limit = 25,
// 	activePage,
// 	activeSearchKeyword,
// 	pageToken,
// 	firstAttempt,
// 	previousPage,
// 	initialCommunication
// }) {
// 	console.log('adfsce', activePage.split('_')[1].replaceAll('.', ''));

// 	if (initialCommunication) {
// 		return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}`;
// 	}
// 	if (previousPage === activePage && pgNo > 1) {
// 		if (activeSearchKeyword !== '') {
// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=from:${activeSearchKeyword}`;
// 		} else {
// 			// N?A unread / personal
// 			if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=is:${activePage}`;
// 			} else if (
// 				activePage === 'CATEGORY_FORUMS' ||
// 				activePage === 'CATEGORY_UPDATES' ||
// 				activePage === 'CATEGORY_PERSONAL' ||
// 				activePage === 'CATEGORY_PROMOTIONS' ||
// 				activePage === 'CATEGORY_SOCIAL' ||
// 				activePage === 'STARRED'
// 			) {
// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=category:${activePage}`;
// 			} else if (activePage === 'UNREAD') {
// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`;
// 			} else {
// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`;
// 			}
// 		}
// 	} else {
// 		if (activeSearchKeyword !== '') {
// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activeSearchKeyword}`;
// 		} else if (activePage !== '') {
// 			if (pgNo > 1) {
// 				if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=is:${activePage}`;
// 				} else if (
// 					activePage === 'CATEGORY_FORUMS' ||
// 					activePage === 'CATEGORY_UPDATES' ||
// 					activePage === 'CATEGORY_PERSONAL' ||
// 					activePage === 'CATEGORY_PROMOTIONS' ||
// 					activePage === 'CATEGORY_SOCIAL' ||
// 					activePage === 'STARRED'
// 				) {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=category:${activePage}`;
// 				} else if (activePage === 'UNREAD') {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`;
// 				} else {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`;
// 				}
// 			} else {
// 				// return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`
// 				if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=is:${activePage}`;
// 				} else if (
// 					activePage === 'CATEGORY_FORUMS' ||
// 					activePage === 'CATEGORY_UPDATES' ||
// 					activePage === 'CATEGORY_PERSONAL' ||
// 					activePage === 'CATEGORY_PROMOTIONS' ||
// 					activePage === 'CATEGORY_SOCIAL' ||
// 					activePage === 'STARRED'
// 				) {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=category:${activePage}`;
// 				} else if (activePage === 'UNREAD') {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`;
// 				} else {
// 					return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`;
// 				}
// 			}
// 		}
// 	}
// }

// export const customEmailUrl = (pgNo = 1, limit = 25, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication) => {
// 	// eslint-disable-next-line react-hooks/rules-of-hooks
// 	// return useMemo(() => getEmailUrl({ pgNo, limit, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication }), [activePage, activeSearchKeyword, firstAttempt, initialCommunication, limit, pageToken, pgNo, previousPage])
// 	return getEmailUrl({ pgNo, limit, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication })
// }

import { createSlice } from '@reduxjs/toolkit';
// import { map, cloneDeep } from 'lodash';
import { getAllTemplates } from '../actions/automation/autotrack/get-all-templates';
import { copyTemplate } from '../actions/automation/autotrack/copy-template';
import { deleteTemplate } from '../actions/automation/autotrack/delete-template';
import { searchTemplate } from '../actions/automation/autotrack/search-template';
import { getTemplateById } from '../actions/automation/autotrack/get-templatebyId';
import { allAutoTracks } from '../actions/autoTrack/allAutoTracks';
import { autotrackStatus } from '../actions/automation/autotrack/autotrackStatus';
import { cloneDeep } from 'lodash';

export const autotrackSlice = createSlice({
	name: 'autotrack',
	initialState: {
		templateList: [],
		autoTrackList: [],
		autotrackForm: {},
		adjustSettings: {},
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: '',
		reload: true,
		editedTemplate: null,
		editMode: null,
		inEdit: false
	},
	reducers: {
		setRelaodEvents: (state, { payload }) => {
			state.reload = payload;
		},
		setTemplateData: (state, action) => {
			// console.log(cloneDeep('bbbb', action))
			console.log('bbbb', action);
			state.editedTemplate = action.payload;
		},
		updateTempalteData: (state, action) => {
			console.log("hmm",{action})
			const newData = {
				...state.editedTemplate,
				...action.payload
			};
			state.editedTemplate = newData;
			return state;
		},
		setEditmode: (state, action) => {
			console.log(cloneDeep('bbbb', action));
			state.editMode = action.payload.mode;
		},
		setInEdit: (state, action) => {
			console.log(cloneDeep('bbbb', action));
			state.inEdit = action.payload;
		},
		setAutomationData: (state, action) => {
			state.automationData = action.payload;
			console.log('actionnn', action.payload);
		},
		clearState: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isFetching = false;
			state.editedTemplate = null;
			state.editMode = null;
			state.inEdit = false;
			return state;
		}
	},

	extraReducers: {
		[getAllTemplates.pending]: (state) => {
			state.isFetching = true;
		},
		[getAllTemplates.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[getAllTemplates.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.templateList = payload;
			return state;
		},
		[copyTemplate.pending]: (state) => {
			state.isFetching = true;
		},
		[copyTemplate.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[copyTemplate.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			return state;
		},
		[deleteTemplate.pending]: (state) => {
			state.isFetching = true;
		},
		[deleteTemplate.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[deleteTemplate.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			return state;
		},
		[searchTemplate.pending]: (state) => {
			state.isFetching = true;
		},
		[searchTemplate.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[searchTemplate.fulfilled]: (state, { payload }) => {
			console.log('mnop:', { payload: cloneDeep(payload) });
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.templateList = payload;
			return state;
		},
		[getTemplateById.pending]: (state) => {
			state.isFetching = true;
		},
		[getTemplateById.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[getTemplateById.fulfilled]: (state, { payload }) => {
			console.log('mnop:', { payload: cloneDeep(payload) });
			state.isFetching = false;
			state.isError = false;
			state.isSuccess = true;
			state.errorMessage = '';
			state.editedTemplate = { ...payload[0] };
			return state;
		},
		[allAutoTracks.pending]: (state) => {
			state.isFetching = true;
		},
		[allAutoTracks.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[allAutoTracks.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.agentsList = payload;
			return state;
		},
		[autotrackStatus.fulfilled]: (state, { payload, meta }) => {
			const body = meta.arg;
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			// const newList = map(state.agentsList, (agent) => {
			// 	if (agent.userid === agentuserid) return { ...agent, disabled };
			// 	else return agent;
			// });
			// state.agentsList = newList;
			// return state;
		}
	}
});
export const {
	clearState,
	setAutomationData,
	setTemplateData,
	updateTempalteData,
	setRelaodEvents,
	setEditmode,
	setInEdit
} = autotrackSlice.actions;
export const autotrackSliceSelector = (state) => state.autotrack;

import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, find, head, map } from 'lodash';
import { getContacts } from '../actions/communications/get-contacts';
import { getMailItems } from '../actions/communications/get-mails';
import { getMsgItems } from '../actions/communications/get-msgs';
import { updateMailStatus } from '../actions/communications/update-status';
import { getEmailLabels } from '../actions/communications/get-email-labels';

export const communicationSlice = createSlice({
	name: 'communications',
	initialState: {
		mails: [],
		pageToken: '',
		msgs: [],
		pgNo: 1,
		firstAttempt: true,
		totalMails: 0,
		totalMsgs: 0,
		activeMail: '',
		activeMsg: '',
		activeMsgId: '',
		activeSearchKeyword: '',
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: '',
		activePage: '',
		previousPage: '',
		activeMsgPage: 'sent',
		contacts: [],
		emailLabelsList: [],
		initialCommunication: false
	},
	reducers: {
		setActivePage: (state, { payload }) => {
			state.activePage = payload.activePage;
			state.previousPage = state.activePage;
			return state;
		},
		setActiveMail: (state, { payload }) => {
			state.activeMail = payload;
			return state;
		},
		setActiveMsg: (state, { payload }) => {
			state.activeMsg = payload;
			return state;
		},
		setActiveSearchKeyword: (state, action) => {
			state.activeSearchKeyword = action.payload;
			return state;
		},
		setAttempt: (state, action) => {
			state.firstAttempt = action.payload
			return state
		},
		setPgNo: (state, action) => {
			state.pgNo = action.payload
			return state
		},
		setInitialCommunication: (state, action) => {
			state.initialCommunication = action.payload
			return state
		}
	},

	extraReducers: {
		[getMailItems.pending]: (state) => {
			state.isFetching = true;
		},
		[getEmailLabels.fulfilled]: (state, { payload }) => {
			state.emailLabelsList = payload;
		},
		[getMailItems.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
			state.mails = [];
			state.totalMails = 0;
			state.pgNo = 1;
		},
		[getMailItems.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.firstAttempt = false;
			state.errorMessage = '';
			state.mails = payload?.[0]?.emails;
			console.log(cloneDeep('bbbb', payload));
			console.log('bbbbb', payload?.[0])
			if (state.pgNo === 1) {
				state.pageToken = payload?.[0]?.PageToken;
			}
			if (state.activePage !== state.previousPage) {
				state.pageToken = payload?.[0]?.PageToken;
			}
			state.totalMails = payload?.[0]?.emailsCount;
			return state;
		},
		[getMsgItems.pending]: (state) => {
			state.isFetching = true;
		},
		[getMsgItems.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[getMsgItems.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.msgs = payload?.[0]?.message;
			state.totalMsgs = payload?.[0]?.messageCount;
			return state;
		},
		[getContacts.fulfilled]: (state, { payload }) => {
			state.contacts = payload;
			return state;
		},
		[updateMailStatus.fulfilled]: (state, { payload, meta }) => {

			const newMails = map(cloneDeep(state?.mails), (mail) => {
				if (mail.mailid === meta?.arg?.mailIDs)
					//return {...mail,read:meta.arg.status === 'read' ? true:false}
					return { ...mail, [meta?.arg?.statusKey]: meta.arg.statusValue }
				else return mail
			})
			state.mails = newMails
			// state.contacts = payload;
			return state;
		}
	}
});
export const { setActivePage, setActiveMail, setActiveMsg, setActiveSearchKeyword, setAttempt, setPgNo, setInitialCommunication } = communicationSlice.actions;
export const mailListSelector = (state) => state.communications.mails;
export const communicationSelector = (state) => state.communications;
export const activePageSelector = (state) => state.communications.activePage;
export const activeMailId = (state) => state.communications.activeMail;

export const activeMailSelector = (state) => {
	return state.communications.activeMail
		? find(state.communications.mails, { mailid: state.communications.activeMail })
		: head(state.communications.activeMail);
};
export const getContactsSelector = (state) => state.communications.contacts;

export const activeMsgSelector = state => {
	return state.communications.activeMsg
		? find(state.communications.msgs, { mailid: state.communications.activeMsg })
		: head(state.communications.activeMsg);
};
export const activeMsgId = (state) => state.communications.activeMsg;
export const activeMsgPageSelector = (state) => state.communications.activeMsgPage;
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, map } from 'lodash';
import { getRoutingLeads } from '../actions/agents/get-routing-leads';
import { updateRoutingAgent } from '../actions/agents/update-routing-agent';

export const leadRoutingSlice = createSlice({
	name: 'leadRoutingSlice',
	initialState: {
		routingLeadsList: [],
		reload: true,
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: ''
	},
	reducers: {
		clearLeadsState: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isFetching = false;
			return state;
		},
		updateLeadFilters: (state, { payload }) => {
			state.appliedFilters = { ...state.appliedFilters, ...payload };
		},
		setActiveLead: (state, { payload }) => {
			state.activeLead = payload;
		},
		setRelaodLeads: (state, { payload }) => {
			state.reload = payload;
		}
	},

	extraReducers: {
		[getRoutingLeads.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isSuccess = true;
			state.routingLeadsList = payload;
		},
		[getRoutingLeads.pending]: (state) => {
			state.isFetching = true;
		},
		[getRoutingLeads.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		[updateRoutingAgent.fulfilled]: (state, { payload, meta }) => {
			console.log('mnop:', { payload: cloneDeep(payload), meta: cloneDeep(meta) });
			state.isFetching = false;
			state.isSuccess = true;
			const newList = map(state.routingLeadsList, (agent) =>
				agent.leadConfigId === meta.arg.leadConfigId
					? {
							...agent,
							...meta.arg,
							excludedSources: meta.arg.excludedSources?.split(',') ?? [],
							exclusiveSources: meta.arg.exclusiveSources?.split(',') ?? [],
							includedSources: meta.arg.includedSources?.split(',') ?? []
					  }
					: agent
			);
			state.routingLeadsList = newList;
		},
		[updateRoutingAgent.pending]: (state) => {
			state.isFetching = true;
		},
		[updateRoutingAgent.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		}
	}
});
// export const { clearLeadsState, updateLeadFilters, setActiveLead, setRelaodLeads } = leadsSlice.actions;
export const leadsRoutingSelector = (state) => state.leadsRouting;

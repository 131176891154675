import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';


export const deleteLabel = createAsyncThunk(
	'leads/deleteLabel',
	async ({ labelIDs, userIDs }, thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/labels`;

			const response = await axios.delete(url, {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				},
				data: {
                    labelID:	labelIDs,
					userIDs
				}
			});

			const { data } = response;
		

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
		
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

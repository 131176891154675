import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export const deleteTemplates = createAsyncThunk(
	'deleteTemplates',
	async ({ templateIDs }, thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/templates`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				},
				data: {
					templateIDs
				}
			};
			const response = await axios.delete(url, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../utils/constants";
import { customEmailUrl } from "../../../hooks/useGetEmailUrl";

const getUrl = ({ pgNo = 1, limit = 25, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication }) => {
	console.log("adfsce", { pageToken, activeSearchKeyword, activePage, firstAttempt, previousPage, initialCommunication })

	return customEmailUrl({ pgNo, limit, activePage, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication })
	//    const URLs={
	//       inbox:`${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}`,
	//       draft:`${SERVER_URL}/dashboard/inbox/drafts?pgNo=${pgNo}&limit=${limit}`,
	//       sent:`${SERVER_URL}/dashboard/inbox/mails/send?pgNo=${pgNo}&limit=${limit}`,
	//       starred:`${SERVER_URL}/dashboard/inbox/emails/starred?pgNo=${pgNo}&limit=${limit}`,
	//       default:`${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}`,
	//    }
	//    return URLs[activePage] || URLs["default"]
	// let URLs = {
	// 	default: `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&label=${activePage}`,
	// }

	// this one
	// if (initialCommunication) {
	// 	return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}`
	// }
	// if (previousPage === activePage && pgNo > 1) {
	// 	if (activeSearchKeyword !== '') {
	// 		return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=from:${activeSearchKeyword}`
	// 	}
	// 	else {
	// 		// N?A unread / personal
	// 		if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
	// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=is:${activePage}`
	// 		} else {
	// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`
	// 		}
	// 	}
	// } else {
	// 	if (activeSearchKeyword !== '') {
	// 		return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activeSearchKeyword}`
	// 	}
	// 	else if (activePage !== '') {
	// 		if (pgNo > 1) {
	// 			if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
	// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=is:${activePage}`
	// 			} else {
	// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activePage}`
	// 			}
	// 		} else {
	// 			// return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`
	// 			if (activePage === 'IMPORTANT' || activePage === 'STARRED') {
	// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=is:${activePage}`
	// 			} else {
	// 				return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activePage}`
	// 			}
	// 		}
	// 	}
	// }

	//

	// if (firstAttempt === true && pgNo === 1 && initialCommunication) {
	// 	return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}`
	// }
	// if (firstAttempt === false) {
	// 	if (activeSearchKeyword !== '') {
	// 		return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=in:${activeSearchKeyword}`
	// 	}
	// 	else if (activePage !== '') {
	// 		if (pgNo > 1) {
	// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=from:${activePage}`
	// 		} else {
	// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&searchKeywords=from:${activePage}`
	// 		}
	// 	}
	// } else {
	// 	if (previousPage === activePage && pgNo > 1) {
	// 		if (activeSearchKeyword !== '') {
	// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=in:${activeSearchKeyword}`
	// 		}
	// 		else {
	// 			return `${SERVER_URL}/dashboard/inbox/mails/received?pgNo=${pgNo}&limit=${limit}&pageToken=${pageToken}&searchKeywords=from:${activePage}`
	// 		}
	// 	}
	// }
}

export const getMailItems = createAsyncThunk(
	"communication/getInbox", async ({ activePage, pgNo, limit, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication }, thunkAPI) => {
		try {
			console.log('kjbkjbkjvjhchch', { activePage, pgNo, limit, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication },
				customEmailUrl({ activePage, pgNo, limit, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication })
			)
			const url = customEmailUrl({ activePage, pgNo, limit, activeSearchKeyword, pageToken, firstAttempt, previousPage, initialCommunication })
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const response = await axios.get(url, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {

			return thunkAPI.rejectWithValue(e.response.data);
		}
	});

import { createSlice } from '@reduxjs/toolkit';
import { filter, map, cloneDeep, uniqBy } from 'lodash';
import { createGroup } from '../actions/leads/create-group';
import { createLabel } from '../actions/leads/create-label';
import { deleteGroup } from '../actions/leads/delete-group';
import { deleteLabel } from '../actions/leads/delete-label';
import { getExtraDetails } from '../actions/leads/get-extra-details';
import { getGroupAndLabels } from '../actions/leads/get-group-n-label';
import { getLeads } from '../actions/leads/get-leads';
import { getSavedFiltersList } from '../actions/leads/get-saved-filters';
import { saveAppliedFilter } from '../actions/leads/save-filter';
import { updateLabel } from '../actions/leads/update-label';

export const appliedFilters = {
	page: 1,
	viewCount: 25,
	searchKeywords: null,
	pipelinename: null,
	sortFilter: 'RegistrationDate',
	RegistrationDate: null,
	sortDirection: 'desc',
	desc: null,
	labelid: null,
	assignedAgents: null,
	sources: null,
	propertyViewsParam: null,
	savedSearchesParam: null,
	houseToSellParam: null,
	firstTimeBuyerParam: null,
	preQualifiedParam: null,
	favoriteCityParam: null,
	subscriptionParam: null,
	termsOfUseParam: null,
	propertyInquiriesParam: null,
	leadType: null,
	registrationDate: null,
	appointments: null,
	AveragePriceParam: null,
	lastlogindate: null,
	loginCount: null,
	nextReminderDate: null
};
export const leadsSlice = createSlice({
	name: 'leadsSlice',
	initialState: {
		leads: { totalLeadsCount: 0, leadsList: [] },
		activeLead: 0,
		extraDetails: {},
		noteAddCheck: false,
		reload: true,
		filterUnchack: true,
		reloadSavedFilters: true,
		groupsAndLabels: {
			groups: [],
			labels: []
		},
		savedFilters: [],
		appliedFilters,
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: '',
		fetchLeadNotes: true,
		fetchLeadAppt: true,
		activityFilters: {
			showPropertyViews: true,
			showNotes: true,
			showEmails: true,
			showTextMessages: true,
			showFavProperty: true,
			showPropertyInquires: true,
			showPipeLines: true,
			showLabels: true
		}
	},
	reducers: {
		clearLeadsState: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isFetching = false;
			return state;
		},
		updateLeadFilters: (state, { payload }) => {
			state.appliedFilters = { ...state.appliedFilters, ...payload };
		},
		setActiveLead: (state, { payload }) => {
			state.activeLead = payload;
		},
		setAddNoteCheck: (state, { payload }) => {
			state.noteAddCheck = payload;
		},
		setRelaodLeads: (state, { payload }) => {
			state.reload = payload;
		},
		setReloadSavedFilters: (state, { payload }) => {
			state.reloadSavedFilters = payload;
		},
		resetFilters: (state, { payload }) => {
			console.log('qwewr', payload);
			state.appliedFilters = { ...appliedFilters };
			state.reload = true;
			state.filterUnchack = payload;
		},
		setFetchLeadNotes: (state, { payload }) => {
			state.fetchLeadNotes = payload;
		},
		setFetchLeadAppt: (state, { payload }) => {
			state.fetchLeadAppt = payload;
		},
		toggleActiveFilter: (state, { payload }) => {
			state.activityFilters[payload?.key] = payload?.value;
		},
		showAllActivityFilters: (state) => {
			state.activityFilters = {
				showPropertyViews: true,
				showNotes: true,
				showEmails: true,
				showTextMessages: true,
				showFavProperty: true,
				showPropertyInquires: true,
				showPipeLines: true,
				showLabels: true
			};
		},
		hideAllActivityFilters: (state) => {
			state.activityFilters = {
				showPropertyViews: false,
				showNotes: false,
				showEmails: false,
				showTextMessages: false,
				showFavProperty: false,
				showPropertyInquires: false,
				showPipeLines: false,
				showLabels: false
			};
		}
	},

	extraReducers: {
		[getLeads.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isSuccess = true;
			state.leads = {
				totalLeadsCount: payload.totalLeadsCount,
				leadsList: uniqBy(payload.leads, 'userid') ?? []
			};
		},
		[getLeads.pending]: (state) => {
			state.isFetching = true;
		},
		[getLeads.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		[getExtraDetails.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isSuccess = true;
			state.extraDetails = payload[0];
		},
		[getGroupAndLabels.fulfilled]: (state, { payload }) => {
			state.groupsAndLabels = payload;
		},
		[getSavedFiltersList.fulfilled]: (state, { payload }) => {
			state.savedFilters = payload?.result;
		},
		[getGroupAndLabels.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		},
		// [saveAppliedFilter.fulfilled]
		[updateLabel.fulfilled]: (state, { payload, meta }) => {
			const { colourHex, groupId, labelId, labelName } = meta.arg;
			state.groupsAndLabels.labels = map(state.groupsAndLabels.labels, (l) => {
				if (l.labelid == payload[0].labelid) {
					return {
						...l,
						colour_hex: colourHex,
						group_id: groupId,
						labelid: labelId,
						labelname: labelName
					};
				} else return l;
			});
		},
		[createLabel.fulfilled]: (state, { payload, meta }) => {
			const { colourHex, groupId, labelName } = meta.arg;
			const prev = [...state.groupsAndLabels.labels];
			prev.push({
				labelid: Number(payload[0]?.labelid),
				group_id: groupId,
				colour_hex: colourHex,
				labelname: labelName
			});
			state.groupsAndLabels.labels = prev;
		},
		[createGroup.fulfilled]: (state, { payload, meta }) => {
			const { groupName } = meta.arg;
			// console.log("rrr The label group", payload)
			console.log('rrr', { payload: cloneDeep(payload) });
			const prev = [...state.groupsAndLabels.groups];
			prev.push({
				l_groupid: Number(payload?.[0]?.l_groupid ?? payload?.[0]?.groupid),
				l_groupname: groupName,
				count: 0
			});
			state.groupsAndLabels.groups = prev;
		},
		[deleteGroup.fulfilled]: (state, { payload, meta }) => {
			const { groupId } = meta.arg;
			const prev = filter([...state.groupsAndLabels.groups], (gp) => gp.l_groupid !== groupId);
			state.groupsAndLabels.groups = prev;
		},
		[deleteLabel.fulfilled]: (state, { payload, meta }) => {
			const { labelIDs } = meta.arg;

			state.groupsAndLabels.labels = filter(
				state.groupsAndLabels.labels,
				(l) => l.labelid !== Number(labelIDs)
			);
		}
	}
});

export const {
	clearLeadsState,
	updateLeadFilters,
	setActiveLead,
	setAddNoteCheck,
	setRelaodLeads,
	resetFilters,
	setReloadSavedFilters,
	setFetchLeadNotes,
	setFetchLeadAppt,
	toggleActiveFilter,
	showAllActivityFilters,
	hideAllActivityFilters
} = leadsSlice.actions;

export const leadsSelector = (state) => state.leadsSlice;
export const extraDetailsSelector = (state) => state.leadsSlice.extraDetails;
export const savedFiltersSelector = (state) => state.leadsSlice.savedFilters;
export const groupsSelector = (state) => state.leadsSlice.groupsAndLabels.groups;
export const labelsSelector = (state) => state.leadsSlice.groupsAndLabels.labels;
export const groupsAndLabelSelector = (state) => state.leadsSlice.groupsAndLabels;
export const leadsAppliedFilterSelector = (state) => state.leadsSlice.appliedFilters;
export const activeLeadIndexSelector = (state) => state.leadsSlice.activeLead;
export const activeLeadSelector = (state) =>
	state.leadsSlice.leads.leadsList[state.leadsSlice.activeLead] ?? {};
export const leadReloadSelector = (state) => state.leadsSlice.reload;
export const filterUnchackSelector = (state) => state.leadsSlice.filterUnchack;
export const leadFetchNotesSelector = (state) => state.leadsSlice.fetchLeadNotes;
export const leadFetchActivitySelector = (state) => ({
	fetchLeadNotes: state.leadsSlice.fetchLeadNotes,
	fetchLeadAppt: state.leadsSlice.fetchLeadAppt
});
export const saveFiltersReloadSelector = (state) => state.leadsSlice.reloadSavedFilters;
export const leadListSelector = (state) => state.leadsSlice.extraDetails?.leads;
export const agentListSelector = (state) => state.leadsSlice.extraDetails?.agents;
export const sourcesSelector = (state) => state.leadsSlice.extraDetails?.lead_sources;

export const showActivityFiltersSelector = (state) => state.leadsSlice.activityFilters;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../../utils/constants';

export const getTemplateById = createAsyncThunk("autotrack/getTemplateById", async ({ value }, thunkAPI) => {
    try {
        const url = `${SERVER_URL}/dashboard/campaign/template1?autotrackId=${value}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem("token"),
            },
        };
        const response = await axios.get(url, config);
        const { data } = response;
        if (response.status === 200 || response.status === 304) {
            return data.result;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});


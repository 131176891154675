import { createSlice } from "@reduxjs/toolkit";
import { setDrawer } from "../actions/utils/setDrawer";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
   open:false,
   active:'',
   isClosable:true,
   drawerProps:{}   
  },
  reducers: {
    closeDrawer: (state) => {
      state.open = false;
      state.active = null;
      state.isClosable = true;
      state.drawerProps = {};
            return state;
    },   
  },
  
  extraReducers: {
    [setDrawer.fulfilled]: (state, { payload }) => {     
          state.open = true;
          state.active = payload.drawer;
          state.isClosable = payload.isClosable;
          state.drawerProps = payload.drawerProps;
         return state
        },
    
    }
});
export const { closeDrawer } = drawerSlice.actions;
export const drawerSelector = state => state.drawer
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export const updateProfile = createAsyncThunk(
	'users/update_profile',
	async (
		{
			email_communication,
			property_alerts,
			text_message,
			email,
			fullName,
			mobile,
			password,
			property_favorites
		},
		thunkAPI
	) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const { user } = thunkAPI.getState();

			const response = await axios.put(
				`${SERVER_URL}/account/update`,
				{
					Email_communication: email_communication,
					Property_alerts: property_alerts,
					Text_message: text_message,
					email,
					fullName: fullName,
					mobile,
					password,
					property_favorites
				},
				config
			);

			const { data } = response;

			if (response.status === 200) {
				localStorage.setItem('email', email);
				localStorage.setItem(
					'user',
					JSON.stringify({
						...user?.user,
						email_communication,
						property_alerts,
						text_message,
						email,
						fullName,
						mobile,
						property_favorites
					})
				);
				return {
					...user?.user,
					email_communication,
					property_alerts,
					text_message,
					email,
					fullName,
					mobile,
					property_favorites
				};
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

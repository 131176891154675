import { createSlice } from "@reduxjs/toolkit"
import { getPropertyDetails } from "../actions/properties/get-property-details";

export const propertyDetailsSlice = createSlice({
  name: "propertyDetails",
  initialState: {
    propertyDetails:null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "", 
  },
  reducers: {
    clearPropertyDetails: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;  
        return state;
      }, 
  },
  
  extraReducers: {
    [getPropertyDetails.fulfilled]: (state, { payload }) => {
    
        state.isFetching = false;
        state.isSuccess = true;
        state.propertyDetails = payload;       
      },
  [getPropertyDetails.pending]: (state) => {
        state.isFetching = true;
      },
  [getPropertyDetails.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message;
      },
    
    }
});
export const { clearPropertyDetails } = propertyDetailsSlice.actions;
export const propertyDetailsSelector = state => state.propertyDetails
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getSavedFiltersList = createAsyncThunk(
	'leads/getSavedFiltersList',
	async (thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/leads/savefilter`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};

			const response = await axios.get(url, config);
			const { data } = response;
			return data;
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

// import axios from 'axios';
// import { SERVER_URL } from '../../../utils/constants';

// export const autotrackscount = async () => {
// 	const config = {
// 		headers: {
// 			'Content-Type': 'application/json',
// 			authorization: `Bearer ${localStorage.getItem('token')}`
// 		}
// 	};
// 	const response = await axios.get(`${SERVER_URL}/dashboard/campaign/autotrackscount`, config);
// 	const { data } = response;
// 	console.log('eeww', data);
// 	return data;
// };
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export const allAutoTracks = createAsyncThunk('autoTrack/campaign', async (thunkAPI) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		};

		const response = await axios.get(`${SERVER_URL}/dashboard/campaign/allautotracks`, config);

		const { data } = response;
		console.log('wwe', { data });

		if (response.status === 200) {
			return { data };
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e) {
		return thunkAPI.rejectWithValue(e.response.data);
	}
});

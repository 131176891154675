import { createSlice } from "@reduxjs/toolkit"

import {search} from '../actions/search/search'


export const searchSlice = createSlice({
  name: "search",
  initialState: {
   properties:[],
   propertiesCount:0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "", 
  },
  reducers: {
    clearSearchState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;  
        return state;
      }, 
  },
  
  extraReducers: {
    [search.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.properties = payload.properties;
        state.propertiesCount = payload.totalCount;
      },
  [search.pending]: (state) => {
        state.isFetching = true;
      },
  [search.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message;
      },
    
    }
});
export const { clearSearchState } = searchSlice.actions;
export const searchSelector = state => state.search
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getUrl = ({ activePage, pgNo = 1, limit = 25 }) => {
	const URLs = {
		sent: `${SERVER_URL}/dashboard/inbox/text/send?pgNo=${pgNo}&limit=25`,
		getReceivedMessage: `${SERVER_URL}/dashboard/inbox/getReceivedMessage?pgNo=${pgNo}&limit=25`,
		default: `${SERVER_URL}/dashboard/inbox/getReceivedMessage?pgNo=${pgNo}&limit=25`
	};
	return URLs[activePage] || URLs['default'];
};

export const getMsgItems = createAsyncThunk(
	'communication/getMsg',
	async ({ activePage, pgNo, limit }, thunkAPI) => {
		try {
			const url = getUrl({ activePage, pgNo, limit });
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const response = await axios.get(url, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);



import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../utils/constants";


export const deleteCalEvent = createAsyncThunk(
	'calendar/deleteEvent',
	async ({ eventId ,type}, thunkAPI) => {
		try {
			const url = `${SERVER_URL}/calendar/appointment`;
		
			const response = await axios.delete(url,{
				headers:  {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				},
				data: {
					eventId
				}
			});
			

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {			
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);



import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../utils/constants";



export const getAppointments = createAsyncThunk(
    "calendar/getAppointments", async ({id,type='calendar',startDate='2021-12-31T18:30:00.000Z',endDate='2024-01-31T18:29:59.999Z'}, thunkAPI) => {
	try {
		const url = `${SERVER_URL}/calendar/appointment?startDate=${startDate}&endDate=${endDate}&type=calendar&leadid=${id ?? 0}`
		const config = {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		};
		const response = await axios.get(url, config);

		const { data } = response;		

		if (response.status === 200) {
			return data.result;
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e) {
		
		return thunkAPI.rejectWithValue(e.response.data);
	}
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export const getGroupAndLabels = createAsyncThunk(
	'leads/getGroupAndLabels',
	async ({}, thunkAPI) => {
		try {
			const getGroupUrl = `${SERVER_URL}/dashboard/labels/group`;
			const getLabelsUrl = `${SERVER_URL}/dashboard/labels`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};

			const groupRequest = axios.get(getGroupUrl, config);
			const labelRequest = axios.get(getLabelsUrl, config);
			const responses = await axios.all([groupRequest, labelRequest]);
			const groupsResponse = responses[0]?.data?.result;
			const labelsResponse = responses[1]?.data?.result;

			const data = {
				groups: groupsResponse,
				labels: labelsResponse
			};
			return data;
		} catch (e) {
		
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

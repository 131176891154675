import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export const updateMailStatus = createAsyncThunk(
	'communication/changeStatus',
	async ({ mailIDs, status = 'read',statusKey ,statusValue}, thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/inbox/emails/status`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const payload = {
				mailIDs,
				status
			};

			const response = await axios.put(url, payload, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {			
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

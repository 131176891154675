import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';


export const getFavProperites = createAsyncThunk('helpers/getFav', async (thunkAPI) => {
	try {
     
		const config = {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		};
       
		 const response = await axios.get(`${SERVER_URL}/properties/favourites`, config);
		
		 const { data } = response;
		
	 const { property_favorites } = data?.result;
	 
		if (response.status === 200) {
			return { property_favorites };
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e) {
		
		return thunkAPI.rejectWithValue(e.response.data);
	}
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export const getLeads = createAsyncThunk('leads/getLeads', async ({ filters }, thunkAPI) => {
	try {
		const url = `${SERVER_URL}/dashboard/leads`;
		const config = {
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		};
		const response = await axios.get(url, {
			params: {
				...filters
			},
			headers: config.headers
		});

		const { data } = response;
	

		if (response.status === 200) {
			return data.result;
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e) {
		
		return thunkAPI.rejectWithValue(e.response.data);
	}
});

import { createSlice } from '@reduxjs/toolkit';
import { map, cloneDeep } from 'lodash';
import { addAgent } from '../actions/agents/add-agent';
import { getAgents } from '../actions/agents/get-agents';
import { updateAgentStatus } from '../actions/agents/update-agent-status';

export const agentSlice = createSlice({
	name: 'agents',
	initialState: {
		agentsList: [],
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: '',
		reload: true
	},
	reducers: {
		setRelaodEvents: (state, { payload }) => {
			state.reload = payload;
		}
	},

	extraReducers: {
		[getAgents.pending]: (state) => {
			state.isFetching = true;
		},
		[getAgents.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
		},
		[getAgents.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			state.agentsList = payload;
			return state;
		},
		[updateAgentStatus.fulfilled]: (state, { payload, meta }) => {
			const { agentuserid, disabled } = meta.arg;
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			const newList = map(state.agentsList, (agent) => {
				if (agent.userid === agentuserid) return { ...agent, disabled };
				else return agent;
			});
			state.agentsList = newList;
			return state;
		},
		[addAgent.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload;
			return state;
		},
		[addAgent.fulfilled]: (state, { payload, meta }) => {
			console.log('mnop:', { payload: cloneDeep(payload), meta: cloneDeep(meta) });
			const { firstName, lastName, email, mobile, companyName, userLevel } = meta.arg;
			state.isFetching = false;
			state.isError = false;
			state.errorMessage = '';
			const newUser = {
				userid: payload?.userid,
				firstname: firstName,
				lastname: lastName,
				mobile,
				email,
				disabled: null,
				companyname: companyName,
				userlevel: 'Agent'
			};
			const newList = [newUser, ...state.agentsList];
			state.agentsList = newList;
			return state;
		}
	}
});

export const agentSliceSelector = (state) => state.agents;

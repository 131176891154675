import { createSlice } from '@reduxjs/toolkit';
import { deleteTemplates } from '../actions/templates/delete-template';
import { getTemplates } from '../actions/templates/get-templates';
import { cloneDeep } from 'lodash';

export const templateSlice = createSlice({
	name: 'template',
	initialState: {
		templates: [],
		reload: true
	},

	reducers: {
		reloadTemplate: (state, { payload }) => {
			console.log('mnopq:', { payload: cloneDeep(payload) });
			state.isError = false;
			state.isSuccess = false;
			state.isFetching = false;
			state.reload = payload;
			return state;
		}
	},

	extraReducers: {
		[getTemplates.fulfilled]: (state, { payload }) => {
			state.isFetching = false;
			state.isSuccess = true;
			state.templates = payload;
			state.reload = false;
		},
		[getTemplates.pending]: (state) => {
			state.isFetching = true;
		},
		[getTemplates.rejected]: (state, { payload }) => {
			state.isFetching = false;
			state.isError = true;
			state.errorMessage = payload.message;
		}
	}
});
export const { reloadTemplate } = templateSlice.actions;
export const templateSelector = (state) => state.templates;
export const templateGroupsSelector = (state) => state.templates?.templates ?? [];

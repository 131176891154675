import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { SERVER_URL } from '../../../../utils/constants';

export const deleteTemplate = createAsyncThunk('autotrack/deleteTemplate', async ({ id }, thunkAPI) => {
    console.log('kkkk', 'id: ', id, thunkAPI)
    console.log(cloneDeep(id))
    try {
        const url = `${SERVER_URL}/dashboard/campaign/template1`;
        // const config = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         authorization: `Bearer ${localStorage.getItem('token')}`
        //     }
        // };
        // const response = await axios.delete(url, config, data: { "autotrackId": id });

        // const { data } = response;


        // const response = await axios.delete(url, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         authorization: `Bearer ${localStorage.getItem('token')}`
        //     },
        //     data: {
        //         "autotrackId": id
        //     }
        // });
        // console.log('cccc', response)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            data: {
                "autotrackId": id
            }
        };
        console.log('cccc', config)

        const response = await axios.delete(url, config);

        const { data } = response;


        if (response.status === 200) {
            return data.result;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {

        return thunkAPI.rejectWithValue(e.response.data);
    }
});


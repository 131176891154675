import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';
import { hostingurl } from '../../../utils/helper-functions';

export const getRole = (roleId) => {
	switch (roleId) {
		case 3:
		case '3':
			return 'admin';
		case 2:
		case '2':
			return 'agent';
		default:
			return 'lead';
	}
};
export const login = createAsyncThunk(
	'users/login',
	async ({ email, password, userIP, systemDate, systemName }, thunkAPI) => {
		try {
			const response = await axios.post(`${SERVER_URL}/account/login`, {
				email,
				password,
				hostingurl,
				ipAddress: userIP,
				machineName: systemName || navigator.platform || 'windows',
				currentTime: systemDate
			});

			const { data } = response;
			console.log('jjkkuu', data);

			const { token, userid, userroleid, ...rest } = data?.result;
			if (response.status === 200) {
				localStorage.setItem('token', data?.result?.token);
				localStorage.setItem('userid', data?.result?.userid);
				localStorage.setItem('userroleid', data?.result?.userroleid);
				localStorage.setItem('email', email);
				localStorage.setItem(
					'user',
					JSON.stringify({ userid, userroleid, email, role: getRole(userroleid), ...rest })
				);
				return { ...data?.result, email, role: getRole(userroleid) };
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);



import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const saveAppliedFilters = async (data) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${localStorage.getItem('token')}`
		}
	};
	const url = `${SERVER_URL}/dashboard/leads/savefilter`;

	return axios.post(url, data, config) ;
};




export const saveAppliedFilter = createAsyncThunk(
	'leads/saveAppliedFilter',
	async (values,thunkAPI) => {
		try {
			const url = `${SERVER_URL}/dashboard/leads/savefilter`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};

			const response = await axios.post(url, values,config);
			const { data } = response;
			return data;
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

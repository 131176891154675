import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../../utils/constants';

export const copyTemplate = createAsyncThunk(
	'autotrack/copyTemplate',
	async ({ valId }, thunkAPI) => {
		let id = valId;
		console.log('tiuas', id);
		try {
			const url = `${SERVER_URL}/dashboard/campaign/copytemplate`;
			const config = {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${localStorage.getItem('token')}`
				}
			};
			const response = await axios.post(url, { autotrackId: id }, config);

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

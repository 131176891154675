import React, { Suspense, lazy } from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import store from './store';
import Loader from './components/Loader/splash';

const App = lazy(() => import('./App'));
ReactDOM.render(
	<Suspense fallback={<Loader />}>
		<Provider store={store}>
			<App />
		</Provider>
	</Suspense>,
	document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { SERVER_URL } from '../../../utils/constants';

export const search = createAsyncThunk(
	'search/search-property',
	async ({ body, filters }, thunkAPI) => {
		try {
			console.log('absrjvnkj hsrtg', body);
			const response = await axios.post(`${SERVER_URL}/properties`, {
				...body,
				// sortBy: body || 'list_price',
				pgNo: filters.pageNo ?? 1,
				limit: filters.pageSize ?? 12
			});

			const { data } = response;

			if (response.status === 200) {
				return data.result;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

export const searchFunction = async ({ filters }) => {
	console.log('jhgjhg', filters);
	return axios.post(`${SERVER_URL}/properties`, {
		...filters,
		sortBy: filters?.sortBy || 'list_price',
		pgNo: 1,
		limit: 12
	});
};

import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { modalSlice } from './slices/modalSlice';
import { drawerSlice } from './slices/drawerSlice';
import { searchSlice } from './slices/searchSlice';
import { userSlice } from './slices/userSlice';
import { propertyDetailsSlice } from './slices/propertyDetailsSlice';
import { helperSlice } from './slices/helperSlice';
import { leadsSlice } from './slices/leadsSlice';
import { communicationSlice } from './slices/communicationSlice';
import { templateSlice } from './slices/templateSlice';
import { calendarSlice } from './slices/calendarSlice';
import { agentSlice } from './slices/agentSlice';
import { leadRoutingSlice } from './slices/leadRoutingSlice';
import { autotrackSlice } from './slices/autotrackSlice';
const logger = createLogger({
	level: 'log' | 'console' | 'warn' | 'error' | 'info'
});

const middleware = [logger];
const reducer = {
	user: userSlice.reducer,
	modal: modalSlice.reducer,
	drawer: drawerSlice.reducer,
	search: searchSlice.reducer,
	propertyDetails: propertyDetailsSlice.reducer,
	helpers: helperSlice.reducer,
	leadsSlice: leadsSlice.reducer,
	communications: communicationSlice.reducer,
	templates: templateSlice.reducer,
	calendar: calendarSlice.reducer,
	agents: agentSlice.reducer,
	leadsRouting: leadRoutingSlice.reducer,
	autotrack: autotrackSlice.reducer
};

const userFromStorage = localStorage.getItem('user')
	? JSON.parse(localStorage.getItem('user'))
	: null;

const preloadedState = {
	user: {
		username: '',
		email: userFromStorage?.email,
		role: userFromStorage?.role,
		user: userFromStorage,
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: ''
	}
};
const store = configureStore({
	reducer: reducer,
	devTools: true,
	preloadedState
	//middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;

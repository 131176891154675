export const removeQuotes = (string) => {
	let s =
		string !== null && string !== undefined
			? typeof string === 'string'
				? string
				: string.join(',')
			: '';
	s = s.includes(`','`) ? s.split(`','`).join(',') : s;
	if (s.charAt(0) === "'" && s.charAt(s.length - 1) === "'") {
		s = s.substring(1, s.length - 1);
	} else {
		s = s;
	}
	return s;
};
export const countDecimals = (value) => {
	if (Math.floor(value) === value) return 0;
	return value.toString().split('.')[1].length || 0;
};

export const numberWithCommas = (value, topperFixed) => {
	var num = '';
	var toFixed = topperFixed ? topperFixed : 4;
	if (value) {
		var x = parseFloat(value);
		if (!x.toString().includes('e-')) {
			if (countDecimals(x) >= 4) {
				num = x.toFixed(toFixed);
			} else {
				num = x;
			}
		} else {
			num = x.toFixed(x.toString().split('-')[1]);
		}
	}
	num = num.toString().split('.');
	var numWithCommas = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	var numAfterPoint = num[1] !== undefined ? '.' + num[1] : '';
	return numWithCommas + numAfterPoint;
};

export const filterAsString = (filterObj) => {
	let str = [];
	if (filterObj.area) str.push(removeQuotes(filterObj.area));
	if (filterObj.city) str.push(removeQuotes(filterObj.city));
	if (filterObj.postal_code) str.push(removeQuotes(filterObj.postal_code));
	if (filterObj.ml) str.push(removeQuotes(filterObj.ml));
	if (filterObj.bedMax) str.push(filterObj.bedMax + ' beds');
	if (filterObj.bathMax) str.push(filterObj.bathMax + ' bath');
	if (!filterObj.priceMin && !filterObj.priceMax) str.push('Any Price');
	if (filterObj.priceMin && !filterObj.priceMax)
		str.push('$' + numberWithCommas(filterObj.priceMin) + '+');
	if (filterObj.priceMax) {
		const minPrice = filterObj.priceMin ? numberWithCommas(filterObj.priceMin) : '0';
		str.push('$' + minPrice + '-' + '$' + numberWithCommas(filterObj.priceMax));
	}
	if (filterObj.typOfbldg) str.push(filterObj.typOfbldg.replace(/^'|'$/g, ''));
	return str.length ? str.join('##') : 'All';
};

export async function copyTextToClipboard(text) {
	if ('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text);
	} else {
		return document.execCommand('copy', true, text);
	}
}

export const hostingurl =
	window.location.hostname === 'localhost'
		? 'dev.hausleads.com'
		: window.location.hostname.includes('www.')
		? window.location.hostname.split('www.')[1]
		: window.location.hostname;
console.log('abcd:', { hostingurl });
